import React, { Fragment } from "react";
import TitleSubtitle from "../TitleSubtitle";
import CustomImage from "../CustomImage";
import { TitleImageArraySectionType } from "@/lib/sanity/types/page";

const TitleImageArraySection: React.FC<TitleImageArraySectionType> = (
  block
) => {
  const { title_subtitle, image_array } = block || {};

  const repeatedImages = image_array
    ? [...image_array, image_array[0], image_array[1]]
    : [];

  return (
    <Fragment>
      <div className="">
        {title_subtitle && <TitleSubtitle block={title_subtitle} />}
        <div className="bg-white-color marqueeText">
          <div className="flex flex-row items-center gap-5 z-10 marqueeTextInner min-w-full  px-3">
            {repeatedImages.map((item, index) => {
              return (
                <div
                className="[&>img]:w-full [&>img]:max-w-[357px] [&>img]:h-[270px] [&>img]:aspect-[4/3]"
                key={index}
              >
                <CustomImage block={item} />
              </div>
              );
            })}
          </div>
          <div className="flex flex-row items-center gap-5 z-10 marqueeTextInner min-w-full px-3">
            {repeatedImages.map((item, index) => {
              return (
                <div
                className="[&>img]:w-full [&>img]:max-w-[357px] [&>img]:h-[270px] [&>img]:aspect-[4/3]"
                key={index}
              >
                <CustomImage block={item} />
              </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TitleImageArraySection;
